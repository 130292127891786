import { format } from "date-fns";

export function formatDate(dateOfBirth: string): string {
  const date = new Date(dateOfBirth);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
}

export function calculateAge(dateOfBirth: string): string {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);

  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }

  return String(age);
}

export function formatTheDoB(date) {
  const [year, month, day] = date.substr(0, 10).split("-");
  return format(new Date(year, month - 1, day), "MMM dd, yyyy");
}
