import { createModel } from "@rematch/core";
import _ from "lodash";
import { User } from "@models/types";
import { RootModel } from ".";

interface EditingUserState {
  user?: User;
  editing: boolean;
  username: string;
  password: string;
  passwordConfirmation: string;
  role: string;
  email: string;
  fullName: string;
}

function initEditingUser(): EditingUserState {
  return {
    user: {} as User,
    editing: false,
    username: "",
    password: "",
    passwordConfirmation: "",
    role: "user",
    email: "",
    fullName: "",
  };
}

export const editingUser = createModel<RootModel>()({
  state: initEditingUser(),
  reducers: {
    setEditing: (state, payload: boolean): EditingUserState => ({
      ...state,
      editing: payload,
    }),
    setUser: (state, payload: User): EditingUserState => ({
      ...state,
      user: payload,
      username: payload.username,
      role: payload.role,
      email: payload.email,
      fullName: payload.fullName,
    }),
    setUsername: (state, payload: string): EditingUserState => ({
      ...state,
      username: payload,
    }),
    setPassword: (state, payload: string): EditingUserState => ({
      ...state,
      password: payload,
    }),
    setPasswordConfirmation: (state, payload: string): EditingUserState => ({
      ...state,
      passwordConfirmation: payload,
    }),
    setRole: (state, payload: string): EditingUserState => ({
      ...state,
      role: payload,
    }),
    setEmail: (state, payload: string): EditingUserState => ({
      ...state,
      email: payload,
    }),
    setFullName: (state, payload: string): EditingUserState => ({
      ...state,
      fullName: payload,
    }),
    clearEditingUser: (state): EditingUserState => initEditingUser(),
  },
  effects: dispatch => ({}),
});
