import { createModel } from "@rematch/core";
import { RootModel } from ".";

export const patientAuditTableState = createModel<RootModel>()({
  state: {}, // initial state
  reducers: {
    setPatientAuditTableState(state, payload) {
      state = payload;
      return state;
    },
    clear: state => ({}),
  },
  effects: dispatch => ({}),
});
