import { createModel } from "@rematch/core";
import { logger } from "@src/logger";
import { User } from "@models/types";
import { RootModel } from ".";

interface UsersState {
  users: User[];
}

function initState(): UsersState {
  return {
    users: [],
  };
}

export const users = createModel<RootModel>()({
  state: initState(),
  reducers: {
    setUsers(state, payload: User[]): UsersState {
      return {
        ...state,
        users: payload,
      };
    },
    clear(state): UsersState {
      return initState();
    },
  },
  effects: dispatch => ({
    async createUser(_: void, rootState) {
      try {
        const api = dispatch.auth.getAuthorizedApi();
        const result = await api.post("/users", {
          username: rootState.addingUser.username,
          password: rootState.addingUser.password,
          password_confirmation: rootState.addingUser.passwordConfirmation,
          email: rootState.addingUser.email,
          full_name: rootState.addingUser.fullName,
          role: rootState.addingUser.role,
        });

        if (result.data?.id) {
          dispatch.users.fetchUsers();
          dispatch.addingUser.setAdding(false);
        }
      } catch (error) {
        dispatch.error.logError(error);
        logger.error(error);
      }
    },
    async fetchUsers(_: void, rootState) {
      try {
        const api = dispatch.auth.getAuthorizedApi();
        const res = await api.get("/users");

        const users = res.data as User[];
        dispatch.users.setUsers(users);

        const auth_user = users.find(
          user => user.id === rootState.auth.user.id,
        );
        if (auth_user) {
          dispatch.auth.setUser(auth_user);
        }
      } catch (error) {
        dispatch.error.logError(error);
        logger.error(error);
      }
    },
    async updateUser(_: void, rootState) {
      try {
        const api = dispatch.auth.getAuthorizedApi();
        const result = await api.put(
          `/users/${rootState.editingUser.user.id}`,
          {
            username: rootState.editingUser.username,
            password: rootState.editingUser.password,
            password_confirmation: rootState.editingUser.passwordConfirmation,
            email: rootState.editingUser.email,
            full_name: rootState.editingUser.fullName,
            role: rootState.editingUser.role,
          },
        );

        if (result.data?.id) {
          dispatch.users.fetchUsers();
          dispatch.editingUser.setEditing(false);
        }
      } catch (error) {
        dispatch.error.logError(error);
        logger.error(error);
      }
    },
    async toggleStatus(user: User, rootState) {
      try {
        const api = dispatch.auth.getAuthorizedApi();
        const result = await api.put(`/users/${user.id}/toggle_disabled`);

        if (result.data?.id) {
          dispatch.users.fetchUsers();
        }
      } catch (error) {
        dispatch.error.logError(error);
        logger.error(error);
      }
    },
  }),
});
