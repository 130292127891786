import { createModel } from "@rematch/core";
import _ from "lodash";
import { QueryableDiagnosis } from "@models/types";
import { logger } from "@src/logger";
import { RootModel } from ".";

interface EditingDiagnosis {
  queryableDiagnosis?: QueryableDiagnosis;
  editing: boolean;
  rating: boolean;
}

function initEditingDiagnosis(): EditingDiagnosis {
  return {
    queryableDiagnosis: {} as QueryableDiagnosis,
    editing: false,
    rating: false,
  };
}

export const editingDiagnosis = createModel<RootModel>()({
  state: initEditingDiagnosis(), // initial state
  reducers: {
    setEditing(state, payload: boolean): EditingDiagnosis {
      state.editing = payload;
      return state;
    },
    setRating(state, payload: boolean): EditingDiagnosis {
      state.rating = payload;
      return state;
    },
    setDiagnosis(state, payload: QueryableDiagnosis): EditingDiagnosis {
      state.queryableDiagnosis = payload;
      return state;
    },
    clearQueryableDiagnosis(state): EditingDiagnosis {
      state = initEditingDiagnosis();
      return state;
    },
  },
  effects: dispatch => ({
    async createQueryableDxAsync(_: void, rootState) {
      try {
        if (
          !rootState.editingDiagnosis.queryableDiagnosis ||
          !rootState.patient.info.sourcePatientId ||
          !rootState.patient.info.source ||
          !rootState.patient.info.uniqueId ||
          !rootState.patient.audit
        ) {
          throw new Error(
            "Missing current audit or diagnosis to create; restart from patient audit page",
          );
        }
        const api = dispatch.auth.getAuthorizedApi();
        const dx = {
          ...rootState.editingDiagnosis.queryableDiagnosis,
          sourcePatientId: rootState.patient.info.sourcePatientId,
          source: rootState.patient.info.source,
          uniqueId: rootState.patient.info.uniqueId,
          patientAuditId: rootState.patient.audit.id,
        } as QueryableDiagnosis;
        const res = await api.post("/diagnoses", dx);
        dispatch.editingDiagnosis.setDiagnosis(res.data);
        dispatch.patient.updateSuggestedDx(res.data);
        dispatch.pendingDiagnoses.addDiagnosis(res.data);
        dispatch.editingDiagnosis.setEditing(false);
      } catch (error) {
        dispatch.error.logError(error);
        logger.error(error);
      }
    },
    async updateQueryableDxAsync(__: void, rootState) {
      try {
        const api = dispatch.auth.getAuthorizedApi();
        const dx = rootState.editingDiagnosis.queryableDiagnosis;
        if (!dx) {
          throw new Error(
            "Missing diagnosis to update; restart from patient audit page",
          );
        }
        const updates = _.cloneDeep(dx);

        const res = await api.put(`/diagnoses/${dx?.id}`, updates);
        dispatch.editingDiagnosis.setDiagnosis(res.data);

        // update patient suggested diagnoses (Patient)
        dispatch.patient.updateSuggestedDx(res.data);

        // update patient existing dx list if the dx is in the list (Workbench)
        const existing = await dispatch.pendingDiagnoses.fetchPendingDxAsync(
          res.data.sourcePatientId,
        );
        console.log("existing", existing);
        dispatch.patient.setExistingDiagnoses(existing);
        // end updating patient existing dx list
        dispatch.pendingDiagnoses.updateDiagnosis(res.data);
      } catch (error) {
        dispatch.error.logError(error);
        logger.error(error);
      }
    },
  }),
});
