import { createModel } from "@rematch/core";
import { logger } from "@src/logger";
import { RootModel } from ".";

interface AuditProgress {
  inProgressCount?: number;
  total?: number;
}

function initProgress() {
  return {
    inProgressCount: undefined,
    total: undefined,
  } as AuditProgress;
}

export const auditProgress = createModel<RootModel>()({
  state: initProgress(), // initial state
  reducers: {
    setProgress(state, payload) {
      state = payload;
      return state;
    },
    clear(state) {
      state = initProgress();
      return state;
    },
  },
  effects: dispatch => ({
    async fetchAuditProgressAsync(_: void, rootState) {
      try {
        const api = dispatch.auth.getAuthorizedApi();
        const res = await api.get("/snp_audit_progress");
        dispatch.auditProgress.setProgress(res.data);
      } catch (error) {
        logger.error(error);
        dispatch.error.logError(error);
      }
    },
  }),
});
