import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Avatar, IconButton, Menu, MenuItem, Divider } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

import { RootState } from "@src/store";

function UserMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const auth = useSelector((state: RootState) => state.auth);

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!auth?.isAuth || !auth?.username) {
    return null;
  }

  return (
    <div>
      <IconButton onClick={handleAvatarClick}>
        <Avatar sx={{ bgcolor: "#90CAF9", color: "#1565C0" }}>
          <PersonIcon />
        </Avatar>
      </IconButton>
      <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          {auth.user?.fullName || auth.username}
        </MenuItem>
        <Divider />
        <Link to="/signout" style={{ color: "black", textDecoration: "none" }}>
          <MenuItem onClick={handleClose}>Log out</MenuItem>
        </Link>
      </Menu>
    </div>
  );
}

export default UserMenu;
