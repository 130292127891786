import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";

import {
  Drawer,
  Badge,
  Toolbar,
  CssBaseline,
  Typography,
  IconButton,
  Box,
  ThemeProvider,
  Theme,
  useTheme,
  styled,
  createTheme,
} from "@mui/material";

import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";

import { RootState } from "@src/store";

import { Loading } from "@molecules/Loading";
import { Notification } from "@molecules/Notification";
import UserMenu from "@molecules/UserMenu";

import SideMenuNav from "@src/organisms/SideMenuNav";

interface DrawerTemplateProps {
  title: string;
  children: React.ReactNode;
}

const drawerWidth = 200;

const AppBar = styled(MuiAppBar)(({ theme }: { theme: Theme }) => ({
  position: "fixed",
  zIndex: theme.zIndex.drawer + 1,
}));

const Overlay = styled("div")<{ open: boolean; theme: Theme }>(
  ({ theme, open }) => ({
    display: open ? "block" : "none",
    position: "fixed",
    width: `calc(100% - ${drawerWidth}px)`,
    height: "100%",
    top: 0,
    left: `${drawerWidth}px`,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: theme.zIndex.drawer - 1,
  }),
);

const MainContent = styled("main")<{ theme: Theme }>(({ theme }) => ({
  padding: theme.spacing(3),
  marginLeft: "60px",
}));

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#2196F3",
    },
  },
  typography: {
    fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#F8FAFC",
          border: "1px solid #EEF2F6",
          radius: "12px",
          padding: "24px",
          boxShadow: "none",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          padding: "6px",
          minWidth: 300,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "none",
        },
        head: {
          fontSize: "0.7rem",
          textTransform: "uppercase",
          color: "gray",
          py: 0.1,
        },
        body: {
          fontWeight: "bold",
          py: 0.1,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          variant: "circular",
          width: "48px",
          height: "48px",
          radius: "100px",
          backgroundColor: "#697586",
          fontSize: "20px",
          fontWeight: "400",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
        },
        virtualScroller: {
          borderBottom: "none",
        },
      },
    },
  },
});

function DrawerTemplate({ title, children }: DrawerTemplateProps) {
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);

  const { loading } = useSelector((state: RootState) => state.loading.global);

  const loadingSuggestions = useSelector(
    (state: RootState) => state.loading.effects.patient.suggestDxAsync.loading,
  );

  const error = useSelector((state: RootState) => state.error);

  if (loading && loadingSuggestions)
    return <Loading longRunning={loadingSuggestions} />;

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{ marginRight: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Medical Coding AI
            </Typography>
            {loading && (
              <IconButton color="inherit">
                <Badge badgeContent={0} color="secondary">
                  <BeatLoader color="#36d7b7" />
                </Badge>
              </IconButton>
            )}
            <Box
              sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}
            >
              <UserMenu />
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              width: open ? drawerWidth : theme.spacing(7),
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <SideMenuNav open={open} />
        </Drawer>
      </Box>
      <Overlay open={open} onClick={() => setOpen(false)} />

      <MainContent open={open}>
        <Toolbar />
        {error.error && <Notification type="error" />}

        <Typography variant="h4" component="div" gutterBottom>
          {title}
        </Typography>
        {children}
      </MainContent>
    </ThemeProvider>
  );
}

export default DrawerTemplate;
