import React, { useCallback, useRef } from "react";
import {
  Box,
  Drawer,
  Typography,
  Button,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import * as _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch, RootState } from "@src/store";

export function EditingUserDrawer() {
  const formRef = useRef(null);
  const dispatch = useDispatch<Dispatch>();

  const {
    editing,
    username,
    password,
    passwordConfirmation,
    fullName,
    email,
    role,
  } = useSelector((state: RootState) => state.editingUser);

  const handleSubmit = useCallback(() => {
    dispatch.users.updateUser();
  }, [dispatch]);

  return (
    <Drawer
      anchor="right"
      open={editing}
      onClose={() => {
        dispatch.editingUser.clearEditingUser();
      }}
    >
      <div
        style={{
          width: `calc(100vw - 800px)`,
          paddingTop: 80,
          paddingLeft: 40,
          paddingRight: 40,
          marginBottom: 80,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box>
            <Typography variant="h6">Edit User</Typography>
          </Box>
          <Box>
            <Button variant="contained" type="button" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            border: 1,
            borderColor: "grey.200",
            borderRadius: "16px",
            marginTop: 2,
            padding: 2,
          }}
        >
          <form ref={formRef} onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, my: 4 }}>
              <InputLabel id="role-label">Role</InputLabel>
              <FormControl variant="outlined" size="small">
                <Select
                  labelId="role-label"
                  value={role}
                  fullWidth
                  onChange={(event: SelectChangeEvent) => {
                    dispatch.editingUser.setRole(event.target.value as string);
                  }}
                  sx={{ minWidth: 120 }}
                >
                  <MenuItem value="user">User</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, my: 4 }}>
              <TextField
                label="Username"
                fullWidth
                value={username}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch.editingUser.setUsername(
                    event.target.value as string,
                  );
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, my: 4 }}>
              <TextField
                label="Password"
                fullWidth
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch.editingUser.setPassword(
                    event.target.value as string,
                  );
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, my: 4 }}>
              <TextField
                label="Password Again"
                fullWidth
                value={passwordConfirmation}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch.editingUser.setPasswordConfirmation(
                    event.target.value as string,
                  );
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, my: 4 }}>
              <TextField
                label="Name"
                fullWidth
                value={fullName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch.editingUser.setFullName(
                    event.target.value as string,
                  );
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, my: 4 }}>
              <TextField
                label="Email Address"
                fullWidth
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch.editingUser.setEmail(event.target.value as string);
                }}
              />
            </Box>
          </form>
        </Box>
      </div>
    </Drawer>
  );
}

export default EditingUserDrawer;
