import React, { useState } from "react";
import { Button } from "@mui/material";

export function ExpandableCell({
  text,
  length = 100,
}: {
  text: string;
  length: number;
}) {
  const [expanded, setExpanded] = useState(false);
  if (!text) {
    return <span />;
  }
  const [content, setContent] = useState(text.substring(0, length));

  return (
    <div style={{ whiteSpace: "normal" }}>
      {content}
      {text.length > length && (
        <Button
          size="small"
          onClick={() => {
            setExpanded(!expanded);
            setContent(text.substring(0, expanded ? length : text.length));
          }}
        >
          {expanded ? "Collapse" : "Expand"}
        </Button>
      )}
    </div>
  );
}

export default ExpandableCell;
