import React, { useRef } from "react";
import {
  Box,
  Drawer,
  Typography,
  Button,
  Rating,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import * as _ from "lodash";
import { Dispatch, RootState } from "@src/store";

import SuggestionsBox from "@molecules/SuggestionsBox";

import { toTitleCase } from "@utils/string";
import { formatToFixedDecimals } from "@utils/number";
import { statusFn, getStatusColor } from "@utils/status";
import { reasonFn } from "@utils/formulations";
import { useParams } from "react-router-dom";

export function EditingDiagnosisDrawer() {
  const dx = useSelector((state: RootState) => state.editingDiagnosis);
  const dispatch = useDispatch<Dispatch>();

  const formRef = useRef(null);

  const { patientId } = useParams();

  const theme = useTheme();
  const { bgcolor, color } = getStatusColor(
    statusFn(dx.queryableDiagnosis.status),
    theme,
  );

  async function handleQuery() {
    await dispatch.editingDiagnosis.createQueryableDxAsync();
  }

  async function handleSave(e) {
    e.preventDefault();
    await dispatch.editingDiagnosis.updateQueryableDxAsync();
  }

  function handleSubmitClick() {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true }),
      );
    }
  }

  const icdCodeSuggestions: Record<string, string> = {
    Code: dx.queryableDiagnosis.icd ?? "Not available",
    Description:
      toTitleCase(dx.queryableDiagnosis.description) ?? "Not available",
    PCC: dx.queryableDiagnosis.isInPcc ?? "Not available",
    GM: dx.queryableDiagnosis.isInGm ?? "Not available",
    "GM Prior": dx.queryableDiagnosis.isInGmPrior ?? "Not available",
    "In Claims": dx.queryableDiagnosis.isInClaims ?? "Not available",
    "Chronic Untreated":
      dx.queryableDiagnosis.isChronicUntreated ?? "Not available",
    HCC: dx.queryableDiagnosis.hcc ?? "Not available",
    Coefficient:
      formatToFixedDecimals(dx.queryableDiagnosis.coefficient) ??
      "Not available",
    Reason: reasonFn(dx.queryableDiagnosis) ?? "Not available",
  };

  return (
    <Drawer
      anchor="right"
      open={dx.editing}
      onClose={() => {
        dispatch.editingDiagnosis.clearQueryableDiagnosis();
        dispatch.editingDiagnosis.setEditing(false);
      }}
    >
      <div
        style={{
          width: `calc(100vw - 800px)`,
          paddingTop: 80,
          paddingLeft: 40,
          paddingRight: 40,
          marginBottom: 80,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box>
            <Typography variant="h6">ICD Code Detail</Typography>
            {!!dx.queryableDiagnosis?.id && (
              <Box
                sx={{
                  display: "flex",
                  borderRadius: "8px",
                  justifyContent: "center",
                  bgcolor,
                  color,
                  padding: "4px 8px",
                  margin: "4px",
                  fontSize: "0.875rem",
                }}
              >
                {statusFn(dx.queryableDiagnosis.status)}
              </Box>
            )}
          </Box>
          <Box>
            <Button
              variant="contained"
              type="button"
              onClick={handleSubmitClick}
            >
              Save
            </Button>
          </Box>
        </Box>

        <SuggestionsBox title="AI Suggestions" data={icdCodeSuggestions} />

        {!dx.queryableDiagnosis?.id && (
          <>
            <br />
            <Button variant="contained" onClick={handleQuery}>
              Start by Confirming Queryable
            </Button>
          </>
        )}
        {!!dx.queryableDiagnosis?.id && (
          <Box
            sx={{
              border: 1,
              borderColor: "grey.200",
              borderRadius: "16px",
              marginTop: 2,
              padding: 2,
            }}
          >
            <Typography
              component="div"
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                borderBottom: 1,
                borderColor: "grey.200",
                paddingBottom: 1,
              }}
            >
              Ratings
            </Typography>

            <form ref={formRef} onSubmit={handleSave}>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, my: 4 }}
              >
                <InputLabel id="status-label">Status</InputLabel>
                <FormControl variant="outlined" size="small">
                  <Select
                    labelId="status-label"
                    value={dx.queryableDiagnosis.status}
                    onChange={(event: SelectChangeEvent) => {
                      dispatch.editingDiagnosis.setDiagnosis({
                        ...dx.queryableDiagnosis,
                        status: event.target.value as string,
                      });
                    }}
                    sx={{ minWidth: 120 }}
                  >
                    <MenuItem value={0}>Saved</MenuItem>
                    <MenuItem value={1}>Alert Sent</MenuItem>
                    <MenuItem value={2}>Approved</MenuItem>
                    <MenuItem value={3}>Rejected</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                label="Coder Notes"
                fullWidth
                value={dx.queryableDiagnosis.coderNotes || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch.editingDiagnosis.setDiagnosis({
                    ...dx.queryableDiagnosis,
                    coderNotes: event.target.value,
                  });
                }}
              />
              <br />
              <br />
              <Typography component="legend">
                ICD Suggestion Accuracy
              </Typography>
              <Rating
                name="simple-controlled"
                value={dx.queryableDiagnosis.coderConfidence}
                onChange={(_, newValue) => {
                  dispatch.editingDiagnosis.setDiagnosis({
                    ...dx.queryableDiagnosis,
                    coderConfidence: newValue || undefined,
                  });
                }}
              />
              <br />
              <br />
              <TextField
                label="Rejected Reason"
                fullWidth
                value={dx.queryableDiagnosis.rejectedReason || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch.editingDiagnosis.setDiagnosis({
                    ...dx.queryableDiagnosis,
                    rejectedReason: event.target.value,
                  });
                }}
              />
            </form>
          </Box>
        )}
      </div>
    </Drawer>
  );
}

export default EditingDiagnosisDrawer;
