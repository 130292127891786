import React from "react";
import { useSelector } from "react-redux";

import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { formatTheDoB } from "@utils/date";

import { RootState } from "@src/store";

import { PatientInfo } from "@models/types";
import { Notification } from "@molecules/Notification";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface StartAuditDialogProps {
  open: boolean;
  patientInfo?: PatientInfo;
  handleClose: () => void;
  handleConfirm: () => void;
}

function StartAuditDialog({
  open,
  patientInfo,
  handleClose,
  handleConfirm,
}): StartAuditDialogProps {
  const headerMapping = [
    { key: "source", label: "Source" },
    { key: "sourcePatientId", label: "Patient ID" },
    { key: "dateOfBirth", label: "DoB" },
    { key: "gender", label: "Gender" },
  ];

  const error = useSelector((state: RootState) => state.error);

  return (
    <BootstrapDialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "10px" } }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>Start new audit</DialogTitle>
      {error.error && <Notification type="error" />}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          p: 2,
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography sx={{ my: 2 }}>
          Do you want to start working on this patient?
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar sx={{ bgcolor: "#808080", fontSize: "0.9rem" }}>
              {patientInfo?.firstName?.[0]}
              {patientInfo?.lastName?.[0]}
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography
              variant="h6"
              sx={{ fontSize: "0.95rem", fontWeight: "bold" }}
            >
              {patientInfo?.firstName} {patientInfo?.lastName}
            </Typography>
          </Grid>
        </Grid>
        {patientInfo && (
          <TableContainer
            component={Paper}
            sx={{ mt: 2, border: "none", boxShadow: "none" }}
          >
            <Table aria-label="patient audit details">
              <TableHead>
                <TableRow>
                  {headerMapping.map(({ label }) => (
                    <TableCell
                      key={label}
                      sx={{
                        fontSize: "0.7rem",
                        border: "none",
                        py: 0.5,
                        textTransform: "uppercase",
                        color: "gray",
                      }}
                    >
                      {label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {headerMapping.map(({ key }) => (
                    <TableCell
                      key={key}
                      sx={{ border: "none", py: 0.5, fontWeight: "bold" }}
                    >
                      {key === "dateOfBirth"
                        ? formatTheDoB(patientInfo[key])
                        : patientInfo[key]}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            p: 2,
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              mr: 1,
              border: "1px solid black",
              color: "black",
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            sx={{ bgcolor: "#2196F3" }}
          >
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default StartAuditDialog;
