import * as React from "react";
import { useMemo, useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Box, useTheme, Tooltip, IconButton } from "@mui/material";
import { Check as CheckIcon, Clear as ClearIcon } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { QueryableDiagnosis } from "@models/types";

import { Dispatch, RootState } from "@src/store";
import { EditingDiagnosisDrawer } from "@organisms/EditingDiagnosisDrawer";
import { Confirmation } from "@molecules/Confirmation";
import { ExpandableCell } from "@atoms/ExpandableCell";

import { toTitleCase } from "@utils/string";
import { formatToFixedDecimals } from "@utils/number";
import { formatDate } from "@utils/date";
import { statusFn, getStatusColor } from "@utils/status";
import { reasonFn } from "@utils/formulations";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridCsvExportOptions,
  GridInitialState,
  useGridApiRef,
} from "@mui/x-data-grid";

interface DxTableProps {
  diagnoses: QueryableDiagnosis[];
  disableAction?: boolean;
  columnVisibility?: Record<string, boolean>;
}

/* eslint-disable react/no-unstable-nested-components */
export function DxTable({
  diagnoses,
  disableAction,
  columnVisibility,
}: DxTableProps) {
  const theme = useTheme();
  const dispatch = useDispatch<Dispatch>();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmedDx, setConfirmedDx] = useState<QueryableDiagnosis>();

  function confidenceFn(dx: QueryableDiagnosis) {
    let confidence = 0;
    if (!dx.isInGm) {
      if (dx.isAiSuggested && dx.aiConfidence) {
        confidence = Math.max(dx.aiConfidence, confidence);
      }
      if (dx.isComorbidity && dx.comorbidityRelevance) {
        confidence = Math.max(dx.comorbidityRelevance, confidence);
      }
      if (dx.isInProgressNotes) {
        confidence = Math.max(1.0, confidence);
      }
    }
    return confidence.toFixed(2);
  }

  function confirmDelete(dx: QueryableDiagnosis) {
    setConfirmedDx(dx);
    setConfirmOpen(true);
  }

  async function handleDelete() {
    if (!confirmedDx) throw new Error("No diagnosis to delete");
    await dispatch.pendingDiagnoses.deleteQueryableDxAsync(confirmedDx);
    setConfirmOpen(false);
  }

  function RowActions({ dx }: { dx: QueryableDiagnosis }) {
    async function handleQuery(dx: QueryableDiagnosis) {
      dispatch.editingDiagnosis.setDiagnosis(dx); // set the diagnosis to be edited and queried
      await dispatch.editingDiagnosis.createQueryableDxAsync();
      dispatch.editingDiagnosis.setRating(true);
    }

    // this is to render query, edit, delete button when current audit matches what's showing on the patient info box

    if (disableAction) return null;

    return (
      <>
        {dx.id && (
          <Button
            variant="outlined"
            onClick={() => confirmDelete(dx)}
            sx={{ my: 2 }}
          >
            Undo
          </Button>
        )}
        {!dx.id && (
          <Button
            variant="contained"
            onClick={() => handleQuery(dx)}
            sx={{ my: 2 }}
          >
            Save
          </Button>
        )}
      </>
    );
  }

  function handleEdit(dx: QueryableDiagnosis) {
    dispatch.editingDiagnosis.setDiagnosis(dx);
    dispatch.editingDiagnosis.setEditing(true);
  }

  function EditAction({ dx }: { dx: QueryableDiagnosis }) {
    if (disableAction) return null;

    return (
      dx.id && (
        <IconButton onClick={() => handleEdit(dx)}>
          <ChevronRightIcon />
        </IconButton>
      )
    );
  }

  const columns: QueryableDiagnosis[] = useMemo(
    () => [
      { field: "sourcePatientId", headerName: "Patient ID", width: 100 },

      { field: "icd", headerName: "ICD", width: 100 },
      {
        field: "description",
        headerName: "Description",
        width: 200,
        renderCell: params => {
          return (
            <ExpandableCell
              text={toTitleCase(params.row.description)}
              length={50}
            />
          );
        },
      },
      {
        field: "isInPcc",
        headerName: "In PCC",
        width: 70,
        renderCell: params => {
          return params.row.isInPcc === true ? (
            <Tooltip title="In PCC">
              <CheckIcon />
            </Tooltip>
          ) : (
            <ClearIcon />
          );
        },
        align: "center",
      },
      {
        field: "isInGm",
        headerName: "In GM",
        width: 70,
        renderCell: params => {
          return params.row.isInGm === true ? (
            <Tooltip title="In GM">
              <CheckIcon />
            </Tooltip>
          ) : (
            <ClearIcon />
          );
        },
        align: "center",
      },
      {
        field: "isInGmPrior",
        headerName: "GM Prior",
        width: 70,
        renderCell: params => {
          return params.row.isInGmPrior === true ? (
            <Tooltip title="In GM Prior Year">
              <CheckIcon />
            </Tooltip>
          ) : (
            <ClearIcon />
          );
        },
        align: "center",
      },
      {
        field: "isChronicUntreated",
        headerName: "Chronic Untreated",
        width: 70,
        renderCell: params => {
          return params.row.isChronicUntreated === true ? (
            <Tooltip title="Chronic Untreated">
              <CheckIcon />
            </Tooltip>
          ) : (
            <ClearIcon />
          );
        },
        align: "center",
      },
      {
        field: "isInClaims",
        headerName: "Claims",
        width: 70,
        renderCell: params => {
          return params.row.isInClaims === true ? (
            <Tooltip
              title={`Last Claim: ${formatDate(params.row.claimFromDate)}`}
            >
              <CheckIcon />
            </Tooltip>
          ) : (
            <ClearIcon />
          );
        },
        align: "center",
      },
      {
        field: "confidence",
        headerName: "Confidence",
        width: 70,
        type: "number",
        align: "center",
        valueGetter: params => {
          return confidenceFn(params.row);
        },
      },
      { field: "hcc", headerName: "HCC", width: 80 },
      {
        field: "coefficient",
        headerName: "Coefficient",
        width: 70,
        valueFormatter: params => {
          if (params.value === null || params.value === "") {
            return "";
          }
          return formatToFixedDecimals(params.value);
        },
        align: "center",
      },
      {
        field: "reason",
        headerName: "Reason",
        width: 260,
        renderCell: params => {
          return <ExpandableCell text={reasonFn(params.row)} />;
        },
      },
      {
        field: "rejectedReason",
        headerName: "Rejected Reason",
        width: 260,
        renderCell: params => {
          return <ExpandableCell text={params.row.rejectedReason} />;
        },
      },
      {
        field: "coderName",
        headerName: "Coder",
        width: 80,
      },
      {
        field: "status",
        headerName: "Status",
        width: 140,
        renderCell: params => {
          const status = statusFn(params.row.status);
          const { bgcolor, color } = getStatusColor(status, theme);

          return (
            <Box
              sx={{
                display: "flex",
                borderRadius: "8px",
                bgcolor,
                color,
                padding: "4px 8px",
                margin: "4px",
                fontSize: "0.875rem",
              }}
            >
              {status}
            </Box>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    if (!disableAction) {
      columns.push(
        {
          field: "action",
          headerName: "Quick Action",
          width: 200,
          renderCell: params => <RowActions dx={params.row} />,
        },
        {
          field: "editAction",
          headerName: "",
          width: 10,
          renderCell: params => <EditAction dx={params.row} />,
          align: "right",
        },
      );
    }
  }, [disableAction]);

  function DxTableToolbar() {
    const csvOptions: GridCsvExportOptions = {
      allColumns: true,
      fileName: "diagnoses",
      fields: [
        "sourcePatientId",
        "icd",
        "description",
        "isInPcc",
        "isInGm",
        "isInGmPrior",
        "isChronicUntreated",
        "isInClaims",
        "confidence",
        "hcc",
        "coefficient",
        "reason",
        "rejectedReason",
        "coderName",
        "status",
      ],
    };
    return (
      <GridToolbarContainer
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarFilterButton />
          <GridToolbarExport csvOptions={csvOptions} />
        </div>
        <div>
          <GridToolbarQuickFilter />
        </div>
      </GridToolbarContainer>
    );
  }

  const dxTableState = useSelector((state: RootState) => state.dxTableState);

  const apiRef = useGridApiRef();

  const [initialState, setInitialState] = React.useState<GridInitialState>();

  const saveSnapshot = React.useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      dispatch.dxTableState.setDxTableState(currentState);
    }
  }, [apiRef]);

  useLayoutEffect(() => {
    setInitialState(dxTableState);

    // handle refresh and navigating away/refreshing
    window.addEventListener("beforeunload", saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener("beforeunload", saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  if (!initialState) {
    return "Loading";
  }

  return (
    <Box>
      <DataGrid
        apiRef={apiRef}
        initialState={{
          columns: {
            columnVisibilityModel: {
              sourcePatientId: false,
              rejectedReason: false,
              ...columnVisibility,
            },
          },
          sorting: {
            sortModel: [{ field: "confidence", sort: "desc" }],
          },
          ...initialState,
        }}
        rows={diagnoses}
        columns={columns}
        getRowId={row => `${row.id ? row.id : row.icd}-${row.sourcePatientId}`}
        components={{ Toolbar: DxTableToolbar }}
        pageSize={100}
        rowsPerPageOptions={[100]}
        getRowHeight={() => "auto"}
      />

      <Confirmation
        open={confirmOpen}
        title="Are you sure?"
        message="This will delete this diagnosis from the database."
        onCancel={() => setConfirmOpen(false)}
        onConfirm={handleDelete}
      />
      <EditingDiagnosisDrawer />
    </Box>
  );
}
/* eslint-enable react/no-unstable-nested-components */

export default DxTable;
