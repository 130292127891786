import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import { PatientInfoCard } from "@molecules/PatientInfoCard";
import { DxTable } from "@organisms/DxTable";
import DrawerTemplate from "@templates/DrawerTemplate";

import { RootState, Dispatch } from "@src/store";
import RatingModal from "@molecules/RatingModal";

export default function Workbench() {
  const patient = useSelector((state: RootState) => state.patient);
  const rating = useSelector(
    (state: RootState) => state.editingDiagnosis.rating,
  );
  const dispatch = useDispatch<Dispatch>();

  return (
    <DrawerTemplate title="Patient">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <PatientInfoCard patient={patient} audit={patient.audit} />
        </Grid>
        {patient.existingDiagnoses && patient.existingDiagnoses.length > 0 && (
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Typography variant="h6" component="div">
                Existing
              </Typography>
              <Box className="mt-2" />
              <DxTable
                columnVisibility={{ sourcePatientId: false, createdAt: false }}
                diagnoses={patient.existingDiagnoses}
                disableAction
              />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Typography variant="h6" component="div">
              Suggestions
            </Typography>
            <Box className="mt-2" />
            <DxTable
              columnVisibility={{
                id: false,
                sourcePatientId: false,
                status: false,
                createdAt: false,
                patientAuditId: false,
              }}
              diagnoses={patient.suggestedDiagnoses}
            />
            <RatingModal
              open={rating}
              onClose={() => dispatch.editingDiagnosis.setRating(false)}
              onSave={async () => {
                await dispatch.editingDiagnosis.updateQueryableDxAsync();
                dispatch.editingDiagnosis.setRating(false);
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </DrawerTemplate>
  );
}
