import { createModel } from "@rematch/core";
import { RootModel } from ".";

export const userTableState = createModel<RootModel>()({
  state: {}, // initial state
  reducers: {
    setUserTableState(state, payload) {
      state = payload;
      return state;
    },
    clear: state => ({}),
  },
  effects: dispatch => ({}),
});
