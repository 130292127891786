export function formatToFixedDecimals(
  num: number | undefined | null,
  decimalPlaces: number = 3,
): string {
  if (typeof num !== "number") {
    return "Not available";
  }

  if (num === 0) {
    return "0";
  }
  return num.toFixed(decimalPlaces);
}
