import { createModel } from "@rematch/core";
import { RootModel } from ".";

interface UserError {
  error?: any;
}

function initError() {
  return {
    error: undefined,
  } as UserError;
}

export const error = createModel<RootModel>()({
  state: initError(), // initial state
  reducers: {
    setError(state, payload) {
      state.error = payload;
      return state;
    },
    clearError(state) {
      state = initError();
      return state;
    },
  },
  effects: dispatch => ({
    async logError(payload, _) {
      if (payload.response && payload.response.status === 401) {
        dispatch.auth.clearUser();
      }
      dispatch.error.setError(payload);
    },
  }),
});
