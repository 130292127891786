import * as React from "react";
import { useState, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@src/store";
import { User } from "@models/types";
import { Box, Button, IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { EditingUserDrawer } from "@organisms/EditingUserDrawer";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridCsvExportOptions,
  GridInitialState,
  useGridApiRef,
} from "@mui/x-data-grid";

interface UserTableProps {
  users: User[];
  columnVisibility?: Record<string, boolean>;
}

function RowActions({
  userRow,
  handleToggleStatusClick,
}: {
  userRow: User;
  handleToggleStatusClick: (_: User) => void;
}) {
  return (
    <Button
      variant="outlined"
      onClick={() => handleToggleStatusClick(userRow)}
      sx={{ my: 2 }}
    >
      {userRow.disabled ? "Activate" : "Deactivate"}
    </Button>
  );
}

function EditAction({
  userRow,
  handleClick,
}: {
  userRow: User;
  handleClick: (_: User) => void;
}) {
  return (
    <IconButton onClick={() => handleClick(userRow)}>
      <ChevronRightIcon />
    </IconButton>
  );
}

function UserTableToolbar() {
  const csvOptions: GridCsvExportOptions = {
    allColumns: true,
    fileName: "users",
    fields: ["username", "fullName", "email", "role", "disabled"],
  };

  return (
    <GridToolbarContainer
      sx={{
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarFilterButton />
        <GridToolbarExport csvOptions={csvOptions} />
      </div>
      <div>
        <GridToolbarQuickFilter />
      </div>
    </GridToolbarContainer>
  );
}

export function UserTable({ users, columnVisibility }: UserTableProps) {
  const dispatch = useDispatch<Dispatch>();

  function handleToggleStatus(userRow: User) {
    dispatch.users.toggleStatus(userRow);
  }

  function handleEdit(userRow: User) {
    dispatch.editingUser.setUser(userRow);
    dispatch.editingUser.setEditing(true);
  }

  const columns: User[] = useMemo(
    () => [
      { field: "username", headerName: "Username", flex: 1 },
      { field: "fullName", headerName: "Name", flex: 1 },
      { field: "email", headerName: "Email Address", flex: 1 },
      { field: "role", headerName: "Role", flex: 1 },
      {
        field: "disabled",
        headerName: "Status",
        flex: 1,
        valueFormatter: params => (params.value ? "Disabled" : "Active"),
      },
      {
        field: "action",
        headerName: "Quick Action",
        flex: 1,
        renderCell: params => (
          <RowActions
            userRow={params.row}
            handleToggleStatusClick={handleToggleStatus}
          />
        ),
      },
      {
        field: "editAction",
        headerName: "",
        width: 10,
        renderCell: params => (
          <EditAction userRow={params.row} handleClick={handleEdit} />
        ),
        align: "right",
      },
    ],
    [],
  );

  const userTableState = useSelector(
    (state: RootState) => state.userTableState,
  );

  const apiRef = useGridApiRef();

  const [initialState, setInitialState] = useState<GridInitialState>();

  const saveSnapshot = React.useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      dispatch.userTableState.setUserTableState(currentState);
    }
  }, [apiRef]);

  useLayoutEffect(() => {
    setInitialState(userTableState);

    // handle refresh and navigating away/refreshing
    window.addEventListener("beforeunload", saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener("beforeunload", saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  if (!initialState) {
    return "Loading";
  }

  return (
    <Box>
      <DataGrid
        apiRef={apiRef}
        initialState={{
          columns: {
            columnVisibilityModel: {
              sourcePatientId: false,
              rejectedReason: false,
              ...columnVisibility,
            },
          },
          sorting: {
            sortModel: [{ field: "confidence", sort: "desc" }],
          },
          ...initialState,
        }}
        rows={users}
        columns={columns}
        getRowId={row => row.id}
        components={{ Toolbar: UserTableToolbar }}
        pageSize={100}
        rowsPerPageOptions={[100]}
        getRowHeight={() => "auto"}
      />
      <EditingUserDrawer />
    </Box>
  );
}

export default UserTable;
