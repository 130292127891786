import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { RootState, Dispatch } from "@src/store";
import { Notification } from "@molecules/Notification";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface RatingModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

export function RatingModal({ open, onClose, onSave }: RatingModalProps) {
  const dispatch = useDispatch<Dispatch>();
  const editing = useSelector(
    (state: RootState) => state.editingDiagnosis.queryableDiagnosis,
  );
  const error = useSelector((state: RootState) => state.error);

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{ sx: { borderRadius: "10px" } }}
    >
      <DialogTitle id="customized-dialog-title" sx={{ m: 0, p: 2 }}>
        Ratings
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {error.error && <Notification type="error" />}
        <Typography gutterBottom>ICD Suggestion Accuracy</Typography>
        <Rating
          name="icd-suggestion-accuracy"
          value={editing.coderConfidence}
          onChange={(_, newValue) => {
            dispatch.editingDiagnosis.setDiagnosis({
              ...editing,
              coderConfidence: newValue,
            });
          }}
        />
        <TextField
          id="additional-notes"
          label="Additional notes"
          multiline
          rows={4}
          fullWidth
          value={editing.coderNotes}
          onChange={event => {
            dispatch.editingDiagnosis.setDiagnosis({
              ...editing,
              coderNotes: event.target.value,
            });
          }}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button autoFocus onClick={onSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default RatingModal;
