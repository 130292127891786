import * as React from "react";
import { useState, useLayoutEffect, useMemo } from "react";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridCsvExportOptions,
  GridInitialState,
  useGridApiRef,
} from "@mui/x-data-grid";

import { Box, IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PatientAudit, QueryableDiagnosis } from "@models/types";
import { Dispatch, RootState } from "@src/store";
import { formatTheDoB } from "@utils/date";
import { patient } from "../models/patient";

interface PatientAuditTableProps {
  patientAudits: PatientAudit[];
  columnVisibility?: Record<string, boolean>;
}

function ViewAction({
  patientAuditRow,
  handleClick,
}: {
  patientAuditRow: PatientAudit;
  handleClick: (_: PatientAudit) => void;
}) {
  return (
    <IconButton
      onClick={() =>
        handleClick({
          audit: patientAuditRow.audit,
          patientInfo: patientAuditRow.patientInfo,
        })
      }
    >
      <ChevronRightIcon />
    </IconButton>
  );
}

interface currentAuditProps {
  audit: PatientAudit;
  patientInfo: any;
}

function PatientAuditTableToolbar() {
  const csvOptions: GridCsvExportOptions = {
    allColumns: true,
    fileName: "patientAudits",
    fields: [
      "patientId",
      "fullName",
      "dob",
      "queryableDiagnosisCount",
      "sentCount",
      "approvedCount",
      "rejectedCount",
      "coder",
    ],
  };

  return (
    <GridToolbarContainer
      sx={{
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarFilterButton />
        <GridToolbarExport csvOptions={csvOptions} />
      </div>
      <div>
        <GridToolbarQuickFilter />
      </div>
    </GridToolbarContainer>
  );
}

export function PatientAuditTable({
  patientAudits,
  columnVisibility,
}: PatientAuditTableProps) {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  function setCurrentAudit({ audit, patientInfo }: currentAuditProps) {
    dispatch.patient.setAudit(audit);
    dispatch.patient.setPatientInfo(patientInfo);
    navigate(`/patient_audits/${audit.sourcePatientId}/diagnoses`);
  }

  const columns: PatientAudit[] = useMemo(
    () => [
      {
        field: "patientId",
        headerName: "Patient ID",
        valueGetter: ({ row }) => {
          return row.audit.sourcePatientId;
        },
      },
      {
        field: "fullName",
        headerName: "Name",
        flex: 1,
        valueGetter: ({ row }) => {
          return `${row.patientInfo.firstName} ${row.patientInfo.lastName}`;
        },
      },
      {
        field: "dob",
        headerName: "DoB",
        flex: 1,
        valueGetter: ({ row }) => {
          return row.patientInfo.dateOfBirth
            ? formatTheDoB(row.patientInfo.dateOfBirth)
            : "";
        },
      },
      { field: "queryableDiagnosisCount", headerName: "Saved" },
      { field: "sentCount", headerName: "Alerted" },
      { field: "approvedCount", headerName: "Approved" },
      { field: "rejectedCount", headerName: "Rejected" },
      {
        field: "coder",
        headerName: "Coder",
        flex: 1,
        valueGetter: ({ row }) => {
          return row.audit.coderName;
        },
      },
      {
        field: "viewAction",
        headerName: "",
        width: 10,
        renderCell: params => (
          <ViewAction
            patientAuditRow={params.row}
            handleClick={setCurrentAudit}
          />
        ),
        align: "right",
      },
    ],
    [],
  );

  const patientAuditTableState = useSelector(
    (state: RootState) => state.patientAuditTableState,
  );

  const apiRef = useGridApiRef();

  const [initialState, setInitialState] = useState<GridInitialState>();

  const saveSnapshot = React.useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      dispatch.patientAuditTableState.setPatientAuditTableState(currentState);
    }
  }, [apiRef]);

  useLayoutEffect(() => {
    setInitialState(patientAuditTableState);

    // handle refresh and navigating away/refreshing
    window.addEventListener("beforeunload", saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener("beforeunload", saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  if (!initialState) {
    return "Loading";
  }

  return (
    <Box>
      <DataGrid
        apiRef={apiRef}
        initialState={{
          columns: {
            columnVisibilityModel: {
              sourcePatientId: false,
              rejectedReason: false,
              ...columnVisibility,
            },
          },
          sorting: {
            sortModel: [{ field: "confidence", sort: "desc" }],
          },
          ...initialState,
        }}
        rows={patientAudits.map(row => {
          return {
            id: row.audit.id,
            ...row,
          };
        })}
        columns={columns}
        getRowId={row => row.id}
        components={{ Toolbar: PatientAuditTableToolbar }}
        pageSize={100}
        rowsPerPageOptions={[100]}
        getRowHeight={() => "auto"}
      />
    </Box>
  );
}

export default PatientAuditTable;
