import React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { Patient } from "@models/types";

import CrosswalkTable from "@molecules/CrosswalkTable";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface CrosswalkIdsDialogProps {
  open: boolean;
  patient: Patient;
  handleClose: () => void;
}

function CrosswalkIdsDialog({
  open,
  patient,
  handleClose,
}: CrosswalkIdsDialogProps): JSX.Element {
  return (
    <BootstrapDialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "10px" } }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>Crosswalk IDs</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box>
          <CrosswalkTable patient={patient} />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            p: 2,
          }}
        >
          <Button onClick={handleClose}>Close</Button>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default CrosswalkIdsDialog;
