export function toTitleCase(str: string | undefined | null): string {
  if (typeof str !== "string") {
    return str as string;
  }

  return str.replace(/\w\S*/g, text =>
    text ? text.charAt(0).toUpperCase() + text.substr(1).toLowerCase() : "",
  );
}

export function getFullName(firstName: string, lastName: string): string {
  return `${toTitleCase(firstName)} ${toTitleCase(lastName)}`;
}

export function getInitials(firstName: string, lastName: string): string {
  return (
    (firstName?.charAt(0).toUpperCase() || "") +
    (lastName?.charAt(0).toUpperCase() || "")
  );
}

export function getGender(genderAbbreviation: string): string {
  switch (genderAbbreviation.toUpperCase()) {
    case "M":
      return "Male";
    case "F":
      return "Female";
    default:
      return "Unknown";
  }
}
