import * as React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Workbench from "@pages/Workbench";
import SignIn from "@pages/SignIn";
import SignOut from "@pages/SignOut";
import Users from "@pages/Users";
import { RootState } from "@src/store";
import PendingDiagnoses from "@pages/PendingDiagnoses";
import PatientAudits from "@pages/PatientAudits";
import Search from "@pages/Search";

interface ProtectedRouteProps {
  isAllowed?: boolean;
  redirectPath?: string;
  children?: React.ReactNode;
}

export function ProtectedRoute({
  isAllowed = true,
  redirectPath = "/signin",
  children = null,
}: ProtectedRouteProps) {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
}

export function RootRouter() {
  const auth = useSelector((state: RootState) => state.auth);

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute isAllowed={auth.isAuth} />}>
        <Route path="" element={<Navigate to="/patient_audits" />} />
        <Route path="/search" element={<Search />} />
        <Route path="/workbench" element={<Workbench />} />
        <Route path="/patient_audits" element={<PatientAudits />} />
        <Route path="/pending_diagnoses" element={<PendingDiagnoses />} />
        <Route
          path="/patient_audits/:patientId/diagnoses"
          element={<PendingDiagnoses />}
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute
              isAllowed={auth?.user?.role === "admin"}
              redirectPath="/"
            />
          }
        >
          <Route path="" element={<Users />} />
        </Route>
        <Route path="/signout" element={<SignOut />} />
      </Route>
      <Route path="/signin" element={<SignIn />} />
    </Routes>
  );
}

export default RootRouter;
