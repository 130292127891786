import applyCaseMiddleware from "axios-case-converter";
import axios from "axios";

const api = applyCaseMiddleware(
  axios.create({
    baseURL: `${process.env.REACT_APP_API_URI}/v1_0`,
    timeout: 360000,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  }),
);

// api.interceptors.response.use(function (response) {
//   // Any status code that lie within the range of 2xx cause this function to trigger
//   // Do something with response data
//   return response;
// }, function (error: AxiosError) {
//   console.log("Intercepted", error);
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error
//   return Promise.reject(error);
// });

export default api;
