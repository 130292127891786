import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import PatientAuditTable from "@organisms/PatientAuditTable";
import { RootState, Dispatch } from "@src/store";
import DrawerTemplate from "@templates/DrawerTemplate";
import StartAuditDialog from "@molecules/StartAuditDialog";

export default function PatientAudits() {
  const dispatch = useDispatch<Dispatch>();

  const navigate = useNavigate();

  const patient = useSelector((state: RootState) => state.patient);

  const patientAudits = useSelector(
    (state: RootState) => state.patientAudits.patientAudits,
  );
  const { inProgressCount, total } = useSelector(
    (state: RootState) => state.auditProgress,
  );

  const [open, setOpen] = useState(false);

  const handleGetNext = async () => {
    await dispatch.patientAudits.fetchNextAuditAsync();
    setOpen(true);
  };

  const handleRefresh = async () => {
    dispatch.patientAudits.fetchAuditsAsync();
    dispatch.auditProgress.fetchAuditProgressAsync();
  };

  const handleStartAudit = async () => {
    if (!patient.audit) throw new Error("No patient to start audit for");

    try {
      setOpen(false);
      await dispatch.patientAudits.startAuditAsync();
      navigate("/workbench");
    } catch (error) {
      dispatch.error.logError(error);
      handleGetNext();
    }
  };

  useEffect(() => {
    if (!patientAudits || patientAudits?.length === 0) handleRefresh();
  }, []);

  return (
    <DrawerTemplate title="Home">
      <Grid className="mb-2" container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Grid>
              <Button variant="contained" onClick={handleGetNext}>
                Get Next
              </Button>
              <span className="mx-2" />
              <Button variant="contained" onClick={handleRefresh}>
                Refresh
              </Button>
              <span className="mx-2">
                Progress: {inProgressCount} / {total}
              </span>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <PatientAuditTable patientAudits={patientAudits} />
      {open && patient.info && (
        <StartAuditDialog
          open={open}
          patientInfo={patient.info}
          handleConfirm={handleStartAudit}
          handleClose={() => {
            setOpen(false);
          }}
        />
      )}
    </DrawerTemplate>
  );
}
