import * as React from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { PatientSearchForm } from "@organisms/PatientSearchForm";
import DrawerTemplate from "@templates/DrawerTemplate";

export default function Search() {
  return (
    <DrawerTemplate title="Search">
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "60vh" }}
      >
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 240,
            width: "40vw",
          }}
        >
          <PatientSearchForm />
        </Paper>
      </Grid>
    </DrawerTemplate>
  );
}
