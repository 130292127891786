import persist from "@rematch/persist";
import { init, RematchDispatch, RematchRootState } from "@rematch/core";
import loading, { ExtraModelsFromLoading } from "@rematch/loading";
import updated, { ExtraModelsFromUpdated } from "@rematch/updated";
import immerPlugin from "@rematch/immer";
import storage from "redux-persist/lib/storage";
import { models, RootModel } from "@src/models";

const whitelist = [
  "auth",
  "patient",
  "editingDiagnosis",
  "pendingDiagnoses",
  "patientAudits",
  "auditProgress",
  "dxTableState",
  "userTableState",
  "patientAuditTableState",
];
const persistConfig = {
  key: "chs-coding",
  storage,
  whitelist,
};

type FullModel = ExtraModelsFromLoading<RootModel, { type: "full" }> &
  ExtraModelsFromUpdated<RootModel>;
export const store = init<RootModel, FullModel>({
  models,
  plugins: [
    updated(),
    loading({ type: "full" }),
    persist(persistConfig),
    immerPlugin({
      whitelist,
    }),
  ],
});
export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;
