import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { Check as CheckIcon, Clear as ClearIcon } from "@mui/icons-material";
import { ExpandableCell } from "@atoms/ExpandableCell";

interface SuggestionsBoxProps {
  title: string;
  data: Record<string, string>;
}

function SuggestionsBox({ title, data }: SuggestionsBoxProps) {
  return (
    <Box
      sx={{
        border: 1,
        borderColor: "grey.200",
        borderRadius: "16px",
        marginTop: 2,
        padding: 2,
      }}
    >
      <Typography
        component="div"
        sx={{
          fontSize: "16px",
          fontWeight: "bold",
          borderBottom: 1,
          borderColor: "grey.200",
          paddingBottom: 1,
        }}
      >
        {title}
      </Typography>

      <Box sx={{ marginTop: 2 }}>
        <Grid container spacing={2}>
          {Object.entries(data).map(([key, value]) => (
            <React.Fragment key={key}>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                  {key}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {key === "GM" ||
                key === "GM Prior" ||
                key === "PCC" ||
                key === "In Claims" ||
                key === "Chronic Untreated" ? (
                  <Typography sx={{ fontSize: "14px", color: "gray" }}>
                    {value === true ? <CheckIcon /> : <ClearIcon />}
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "14px", color: "gray" }}>
                    {value}
                  </Typography>
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default SuggestionsBox;
