import { createModel } from "@rematch/core";
// import * as _ from "lodash";
import { logger } from "@src/logger";
import { RootModel } from ".";
import {
  QueryableDiagnosis,
  Patient,
  PatientInfo,
  PatientAudit,
} from "./types";

export function checkTimeAndThrowErrorFirstSevenMinute(): void {
  const currentTime = new Date(); // Get the current time
  const minutes = currentTime.getMinutes(); // Extract the minutes part

  if (minutes >= 0 && minutes <= 8) {
    throw new Error(
      "Current time is within the first 8 minutes of the hour. Please try it after",
    );
  }
}

function initPatient(): Patient {
  return {
    audit: {} as PatientAudit,
    info: {} as PatientInfo,
    suggestedDiagnoses: [],
    existingDiagnoses: [],
  };
}

export const patient = createModel<RootModel>()({
  state: initPatient(), // initial state
  reducers: {
    // handle state changes with pure functions using immer
    setSource(state, payload: string): Patient {
      state.info.source = payload;
      return state;
    },
    setPatientId(state, payload: string): Patient {
      state.info.sourcePatientId = payload;
      return state;
    },
    setPatientInfo(state, payload: PatientInfo): Patient {
      state.info = payload as PatientInfo;
      return state;
    },
    setAudit(state, payload: PatientAudit): Patient {
      state.audit = payload as PatientAudit;
      return state;
    },
    setSuggestedDiagnoses(state, payload: QueryableDiagnosis[]): Patient {
      state.suggestedDiagnoses = payload;
      return state;
    },
    setExistingDiagnoses(state, payload: QueryableDiagnosis[]): Patient {
      state.existingDiagnoses = payload;
      return state;
    },
    updateSuggestedDx(state, payload: QueryableDiagnosis): Patient {
      const i = _.findIndex(
        state.suggestedDiagnoses,
        dx => dx.icd === payload.icd,
      );
      if (i > -1) {
        state.suggestedDiagnoses[i] = payload;
      }
      return state;
    },
    clearPatient(): Patient {
      return initPatient();
    },
  },
  effects: dispatch => ({
    async suggestDxAsync(payload: { source: string; sourcePatientId: string }) {
      try {
        checkTimeAndThrowErrorFirstSevenMinute();
        const api = dispatch.auth.getAuthorizedApi();
        const res = await api.post("/suggest_diagnoses", {
          source: payload.source,
          sourcePatientId: String(payload.sourcePatientId),
        });
        const existing = res.data.existingDiagnoses as QueryableDiagnosis[];
        dispatch.patient.setExistingDiagnoses(existing);

        const existingIcds = existing.map(dx => dx.icd);

        const dxs: any = [];
        for (const dx of res.data.suggestedDiagnoses) {
          if (!existingIcds.includes(dx.icd)) {
            dxs.push(dx as QueryableDiagnosis);
          }
        }

        dispatch.patient.setSuggestedDiagnoses(dxs);
        dispatch.patient.setPatientInfo(res.data.patientInfo);
      } catch (error) {
        dispatch.error.logError(error);
        logger.error(error);
      }
    },
  }),
});
