import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button } from "@mui/material";
import UserTable from "@organisms/UserTable";
import { RootState, Dispatch } from "@src/store";
import DrawerTemplate from "@templates/DrawerTemplate";
import AddingUserDrawer from "@organisms/AddingUserDrawer";

export default function Users() {
  const dispatch = useDispatch<Dispatch>();

  const users = useSelector((state: RootState) => state.users.users);

  const handleRefresh = async () => {
    dispatch.users.fetchUsers();
  };

  const handleAddUser = async () => dispatch.addingUser.setAdding(true);

  useEffect(() => {
    if (!users || users?.length === 0) handleRefresh();
  }, []);

  return (
    <DrawerTemplate title="Users">
      <Grid className="mb-2" container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Grid>
              <Button variant="contained" onClick={handleAddUser}>
                Add User
              </Button>
              <span className="mx-2" />
              <Button variant="contained" onClick={handleRefresh}>
                Refresh
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <UserTable users={users} />
      <AddingUserDrawer />
    </DrawerTemplate>
  );
}
