import { createModel } from "@rematch/core";
import { logger } from "@src/logger";
import { PatientAudit, PatientInfo } from "@models/types";
import { checkTimeAndThrowErrorFirstSevenMinute } from "@models/patient";
import { RootModel } from ".";

function initState() {
  return {
    patientAudits: [] as PatientAudit[],
  };
}

interface SearchProps {
  sourcePatientId: string;
  source: string;
}

export const patientAudits = createModel<RootModel>()({
  state: initState(),
  reducers: {
    setAudits(state, payload: PatientAudit[]) {
      state.patientAudits = payload;
      return state;
    },
    clear(state) {
      state = initState();
      return state;
    },
  },
  effects: dispatch => ({
    async fetchNextAuditAsync(_: void, rootState) {
      try {
        const api = dispatch.auth.getAuthorizedApi();
        const res = await api.get("/next_patient");

        dispatch.patient.setPatientInfo(res.data as PatientInfo);
      } catch (error) {
        dispatch.error.logError(error);
        logger.error(error);
      }
    },
    async findOrCreatePatientAuditAsync(
      { sourcePatientId, source }: SearchProps,
      _,
    ) {
      try {
        dispatch.patient.setPatientId(String(sourcePatientId));
        dispatch.patient.setSource(source);
        const api = dispatch.auth.getAuthorizedApi();

        const res = await api.post("/find_or_create_patient_audit", {
          sourcePatientId: String(sourcePatientId),
          source,
        });

        dispatch.patient.setAudit(res.data as PatientAudit);
        dispatch.patient.setPatientInfo({
          source: res.data.source,
          sourcePatientId: res.data.sourcePatientId,
          uniqueId: res.data.uniqueId,
        } as PatientInfo);
      } catch (error) {
        dispatch.error.logError(error);
        logger.error(error);
      }
    },
    async createAuditAsync(_: void, rootState) {
      try {
        const api = dispatch.auth.getAuthorizedApi();
        const res = await api.post("/patient_audits", rootState.patient.info);
        dispatch.patient.setAudit(res.data as PatientAudit);
      } catch (error) {
        dispatch.error.logError(error);
        logger.error(error);
      }
    },
    async fetchAuditsAsync(_: void, rootState) {
      try {
        const api = dispatch.auth.getAuthorizedApi();
        const res = await api.get("/patient_audits");

        dispatch.patientAudits.setAudits(res.data as PatientAudit[]);
      } catch (error) {
        dispatch.error.logError(error);
        logger.error(error);
      }
    },
    async startSearchAuditAsync(searchProps: SearchProps, rootState) {
      checkTimeAndThrowErrorFirstSevenMinute();

      await dispatch.patientAudits.findOrCreatePatientAuditAsync(searchProps);

      dispatch.patient.setSource(searchProps.source);
      dispatch.patient.setPatientId(searchProps.sourcePatientId);
      await dispatch.patient.suggestDxAsync(searchProps);
    },
    async startAuditAsync(_: void, rootState) {
      checkTimeAndThrowErrorFirstSevenMinute();
      if (!rootState.patient.audit) {
        throw new Error("No patient to audit");
      }

      const params = {
        sourcePatientId: rootState.patient.info.sourcePatientId,
        source: rootState.patient.info.source,
      };

      await dispatch.patientAudits.createAuditAsync();
      await dispatch.patientAudits.fetchAuditsAsync(); // update the table
      dispatch.patient.setSource(params.source);
      dispatch.patient.setPatientId(params.sourcePatientId);
      await dispatch.patient.suggestDxAsync(params);
    },
    async rerunAuditAsync(_: void, rootState) {
      checkTimeAndThrowErrorFirstSevenMinute();
      if (!rootState.patient.audit) {
        throw new Error("No audit selected");
      }

      const params = {
        sourcePatientId: rootState.patient.audit.sourcePatientId,
        source: rootState.patient.audit.source,
      } as { sourcePatientId: string; source: string };

      if (!params.sourcePatientId || !params.source) {
        throw new Error("Invalid patient audit");
      }

      dispatch.patient.setSource(params.source);
      dispatch.patient.setPatientId(params.sourcePatientId);
      await dispatch.patient.suggestDxAsync(params);
    },
  }),
});
