import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Patient } from "@models/types";

function CrosswalkTable({ patient }: { patient: Patient }) {
  if (!patient || !patient.info || !patient.info.identities) {
    return null;
  }
  const id_columns = [
    {
      field: "source",
      headerName: "Source",
      width: 100,
    },
    {
      field: "fieldName",
      headerName: "Field Name",
      width: 200,
    },
    {
      field: "id",
      headerName: "ID",
      width: 200,
    },
  ];

  return (
    <DataGrid
      sx={{
        fontSize: 13,
        border: 0,
        "& .MuiTablePagination-displayedRows": {
          margin: 0,
          fontSize: 12,
        },
        "& .MuiDataGrid-cell": {
          border: 0,
        },
      }}
      columns={id_columns}
      rows={patient.info.identities}
      rowHeight={20}
      columnHeaderHeight={20}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
    />
  );
}

export default CrosswalkTable;
