import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Dispatch } from "@src/store";
import { Confirmation } from "@molecules/Confirmation";
import { useNavigate } from "react-router-dom";

export function PatientSearchForm() {
  const dispatch = useDispatch<Dispatch>();
  const [source, setSourceType] = useState<string>("");
  const [sourcePatientId, setPatientId] = useState<string>("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleSourceChange = (event: SelectChangeEvent) => {
    setSourceType(event.target.value);
  };

  const handlePatientIdChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setPatientId(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(true);
  };

  const handleSearch = async () => {
    handleClose();
    if (source && sourcePatientId) {
      try {
        await dispatch.patientAudits.startSearchAuditAsync({
          source,
          sourcePatientId,
        });
        navigate("/workbench");
      } catch (error) {
        dispatch.error.logError(error);
        throw error;
      }
    } else {
      const error = new Error("Please select a source and enter a patient ID");
      dispatch.error.logError(error);
      throw error;
    }
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="source-select-label">Source</InputLabel>
        <Select
          labelId="source-select-label"
          id="source-select"
          label="Source"
          onChange={handleSourceChange}
        >
          <MenuItem value="GM">GM Patient ID</MenuItem>
          <MenuItem value="PCC">PCC Patient ID</MenuItem>
        </Select>
        <TextField
          id="standard-basic"
          onChange={handlePatientIdChange}
          label="Patient ID"
          variant="outlined"
          className="mt-3"
        />
        <Button variant="contained" onClick={handleConfirm} className="mt-5">
          Search
        </Button>
      </FormControl>
      <Confirmation
        open={open}
        title="Start a new patient analysis?"
        message="This is a long running process that could take up to 1 minute. Are you sure you want to continue?"
        onCancel={handleClose}
        onConfirm={handleSearch}
      />
    </Box>
  );
}

export default PatientSearchForm;
