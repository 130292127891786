import { createModel } from "@rematch/core";
import { logger } from "@src/logger";
import { User } from "@models/types";
import { RootModel } from ".";
import api from "./apiClient";

interface AuthState {
  username?: string;
  accessToken?: string;
  isAuth: boolean;
  user?: User;
}

function initAuth(): AuthState {
  return {
    username: undefined,
    user: undefined,
    accessToken: undefined,
    isAuth: false,
  };
}

export const auth = createModel<RootModel>()({
  state: initAuth(), // initial state
  reducers: {
    // handle state changes with pure functions
    setUsername(state, payload: string): AuthState {
      state.username = payload;
      return state;
    },
    setUser(state, payload: User): AuthState {
      state.user = payload;
      return state;
    },
    setAccessToken(state, payload: string): AuthState {
      state.accessToken = payload;
      state.isAuth = true;
      return state;
    },
    clearUser(state): AuthState {
      api.defaults.headers.common.Authorization = undefined;
      state = initAuth();
      return state;
    },
  },
  effects: dispatch => ({
    async loginAsync(payload: { username: string; password: string }, _) {
      try {
        const res = await api.post(
          "/token",
          {
            username: payload.username,
            password: payload.password,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          },
        );
        dispatch.auth.setUsername(payload.username);
        dispatch.auth.setUser(res.data.user);
        dispatch.auth.setAccessToken(res.data.accessToken);
      } catch (error) {
        dispatch.error.logError(error);
        logger.error(error);
      }
    },
    getAuthorizedApi(_: void, rootState) {
      api.defaults.headers.common.Authorization = `Bearer ${rootState.auth.accessToken}`;
      return api;
    },
    async logoutAsync(_: void, rootState) {
      dispatch.addingUser.clearAddingUser();
      dispatch.auditProgress.clear();
      dispatch.auth.clearUser();
      dispatch.dxTableState.clear();
      dispatch.editingDiagnosis.clearQueryableDiagnosis();
      dispatch.editingUser.clearEditingUser();
      dispatch.error.clearError();
      dispatch.patient.clearPatient();
      dispatch.patientAudits.clear();
      dispatch.patientAuditTableState.clear();
      dispatch.pendingDiagnoses.clear();
      dispatch.users.clear();
      dispatch.userTableState.clear();
    },
  }),
});
