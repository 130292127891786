import React, { useEffect, useState } from "react";

import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  const { value, ...otherProps } = props;
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" value={value} {...otherProps} />
    </Box>
  );
}

function LongRunningLoading(props: CircularProgressProps & { value: number }) {
  const { value, otherProps } = props;
  return (
    <Box
      className="h-screen flex items-center justify-center"
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CircularProgress variant="determinate" value={value} {...otherProps} />
      <Typography
        component="div"
        color="black"
        sx={{ fontSize: "1rem", my: 2, fontWeight: "500" }}
      >
        Loading suggested diagnoses for this patient
      </Typography>
      <Typography component="div" color="gray" sx={{ fontSize: "0.8rem" }}>
        This may take up to a few minutes
      </Typography>
    </Box>
  );
}

export function Loading({ longRunning }: { longRunning?: boolean }) {
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress =>
        prevProgress >= 100 ? 0 : prevProgress + 10,
      );
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      {!longRunning && (
        <Box className="h-screen flex items-center justify-center">
          <CircularProgressWithLabel value={progress} />
        </Box>
      )}
      {longRunning && <LongRunningLoading value={progress} />}
    </>
  );
}

export default Loading;
