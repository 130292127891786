import React, { useState } from "react";

import {
  Card,
  Avatar,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Box,
} from "@mui/material";

import { Patient, PatientAudit } from "@models/types";

import CrosswalkIdsDialog from "@molecules/CrosswalkIdsDialog";

import { getFullName, getInitials, getGender } from "@utils/string";
import { formatDate, calculateAge, formatTheDoB } from "@utils/date";

export function PatientInfoCard({
  patient,
  audit,
}: {
  patient: Patient;
  audit: PatientAudit;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleViewIdsClick = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Card sx={{ padding: 2 }}>
      <Box display="flex" alignItems="center" width="100%" overflow="auto">
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Box p={1}>
            <Avatar>
              {getInitials(patient.info.firstName, patient.info.lastName)}
            </Avatar>
          </Box>
          <Box p={2} flexShrink={0}>
            <Typography variant="h6" sx={{ fontSize: "16px" }}>
              {getFullName(patient.info.firstName, patient.info.lastName)}
            </Typography>

            <Typography>{patient.info.sourcePatientId}</Typography>
          </Box>
        </Box>

        <Divider
          orientation="vertical"
          sx={{
            height: 60,
            mx: 2,
            borderWidth: "1px",
            borderColor: "#EEF2F6",
          }}
        />

        <Box sx={{ maxWidth: 800, maxHeight: 400, overflow: "auto" }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ py: 0.2 }}>Gender</TableCell>
                <TableCell sx={{ py: 0.2, minWidth: 160 }}>Dob</TableCell>
                <TableCell sx={{ py: 0.2 }}>Age</TableCell>
                <TableCell sx={{ py: 0.2 }}>Source</TableCell>
                <TableCell sx={{ py: 0.2, minWidth: 200 }}>
                  Audit Created At
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ py: 0.2 }}>
                  {getGender(patient.info.gender)}
                </TableCell>
                <TableCell sx={{ py: 0.2 }}>
                  {formatTheDoB(patient.info.dateOfBirth)}
                </TableCell>
                <TableCell sx={{ py: 0.2 }}>
                  {calculateAge(patient.info.dateOfBirth)}
                </TableCell>
                <TableCell sx={{ py: 0.2 }}>{patient.info.source}</TableCell>
                <TableCell sx={{ py: 0.2 }}>
                  {audit ? formatDate(audit.createdAt) : ""}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>

        <Divider
          orientation="vertical"
          sx={{
            height: 60,
            mx: 2,
            borderWidth: "1px",
            borderColor: "#EEF2F6",
          }}
        />

        <Box sx={{ maxWidth: 500, maxHeight: 400, overflow: "auto" }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ py: 0.2 }}>Total ICD</TableCell>
                <TableCell sx={{ py: 0.2 }}>Saved ICD</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ py: 0.2 }}>
                  {patient?.suggestedDiagnoses?.length}
                </TableCell>
                <TableCell sx={{ py: 0.2 }}>
                  {patient?.existingDiagnoses?.length}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>

        <Box ml="auto" p={2}>
          <Button
            variant="outlined"
            sx={{
              color: "primary.main",
              borderColor: "primary.main",
              "&:hover": {
                borderColor: "primary.dark",
                backgroundColor: "transparent",
              },
            }}
            onClick={handleViewIdsClick}
          >
            View IDs
          </Button>
          {isOpen && (
            <CrosswalkIdsDialog
              open={isOpen}
              handleClose={closeModal}
              patient={patient}
            />
          )}
        </Box>
      </Box>
    </Card>
  );
}
