export function statusFn(status) {
  switch (status) {
    case "0":
      return "Saved";
    case "1":
      return "Alert Sent";
    case "2":
      return "Approved";
    case "3":
      return "Rejected";
    case null:
      return "Null";
    default:
      return "Not Saved";
  }
}

export function getStatusColor(status, theme) {
  switch (status) {
    case "Saved":
      return {
        bgcolor: theme.palette.secondary.main,
        color: theme.palette.common.white,
      };
    case "Alert Sent":
      return {
        bgcolor: theme.palette.info.main,
        color: theme.palette.common.white,
      };
    case "Approved":
      return {
        bgcolor: theme.palette.success.main,
        color: theme.palette.common.white,
      };
    case "Rejected":
      return {
        bgcolor: theme.palette.error.main,
        color: theme.palette.common.white,
      };
    default:
      return {
        bgcolor: theme.palette.grey[300],
        color: theme.palette.common.black,
      };
  }
}
