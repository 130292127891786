import { QueryableDiagnosis } from "@models/types";

import { formatDate } from "@utils/date";

export function reasonFn(dx: QueryableDiagnosis) {
  const reason = [];

  if (dx.isAiSuggested && dx.aiConfidence) {
    reason.push(dx.aiReason);
  }
  if (dx.isComorbidity && dx.comorbidityRelevance) {
    reason.push(`Comorbidity relevance: ${dx.comorbidityRelevance}`);
  }
  if (dx.isInProgressNotes) {
    reason.push(`In progress notes: ${dx.noteTexts}`);
  }
  if (dx.isInClaims) {
    reason.push(`In claims: ${formatDate(dx.claimFromDate)}`);
  }
  if (dx.isInPcc && !dx.isInGm) {
    reason.push(`In PCC, not in GM`);
  }

  if (dx.isInGmPrior && !dx.isInGm) {
    reason.push(`In GM prior year, not in GM current year`);
  }

  return reason.join(" / ");
}
