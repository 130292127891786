import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { List, ListItemButton, ListItemText, styled } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import SubjectIcon from "@mui/icons-material/Subject";
import SearchIcon from "@mui/icons-material/Search";
import GroupsIcon from "@mui/icons-material/Groups";
import { useSelector } from "react-redux";

const StyledListItemButton = styled(({ to, ...otherProps }) => (
  <NavLink to={to} style={{ textDecoration: "none", color: "inherit" }}>
    <ListItemButton {...otherProps} />
  </NavLink>
))(({ to }) => {
  const location = useLocation();
  const isTargetPath = location.pathname === to;

  return {
    padding: "8px 12px",
    margin: "0 4px",
    borderRadius: "6px",
    backgroundColor: isTargetPath ? "rgba(33, 150, 243, 0.08)" : "white",
    color: isTargetPath ? "#2196F3" : "black",
    "&:hover": {
      backgroundColor: "#D9D9D9",
      color: "#2196F3",
    },
  };
});

const StyledListItemText = styled(ListItemText)(() => ({
  marginLeft: "20px",
}));

function SideMenuNav({ open }) {
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <List
      sx={{
        alignItems: "center",
        justifyContent: "start",
        margin: "20px 0",
      }}
    >
      <>
        <StyledListItemButton to="/patient_audits">
          <GridViewIcon />
          {open && <StyledListItemText primary="Home" />}
        </StyledListItemButton>
        <StyledListItemButton to="/pending_diagnoses">
          <SubjectIcon />
          {open && <StyledListItemText primary="Workbench" />}
        </StyledListItemButton>
        <StyledListItemButton to="/search">
          <SearchIcon />
          {open && <StyledListItemText primary="Search" />}
        </StyledListItemButton>
        {user?.role === "admin" && (
          <StyledListItemButton to="/users">
            <GroupsIcon />
            {open && <StyledListItemText primary="Users" />}
          </StyledListItemButton>
        )}
      </>
    </List>
  );
}

export default SideMenuNav;
