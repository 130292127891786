import { createModel } from "@rematch/core";
import _ from "lodash";
import { RootModel } from ".";

interface AddingUserState {
  adding: boolean;
  username: string;
  password: string;
  passwordConfirmation: string;
  role: string;
  email: string;
  fullName: string;
}

function initAddingUser(): AddingUserState {
  return {
    adding: false,
    username: "",
    password: "",
    passwordConfirmation: "",
    role: "user",
    email: "",
    fullName: "",
  };
}

export const addingUser = createModel<RootModel>()({
  state: initAddingUser(), // initial state
  reducers: {
    setAdding: (state, payload: boolean): AddingUserState => ({
      ...state,
      adding: payload,
    }),
    setUsername: (state, payload: string): AddingUserState => ({
      ...state,
      username: payload,
    }),
    setPassword: (state, payload: string): AddingUserState => ({
      ...state,
      password: payload,
    }),
    setPasswordConfirmation: (state, payload: string): AddingUserState => ({
      ...state,
      passwordConfirmation: payload,
    }),
    setRole: (state, payload: string): AddingUserState => ({
      ...state,
      role: payload,
    }),
    setEmail: (state, payload: string): AddingUserState => ({
      ...state,
      email: payload,
    }),
    setFullName: (state, payload: string): AddingUserState => ({
      ...state,
      fullName: payload,
    }),
    clearAddingUser: (state): AddingUserState => initAddingUser(),
  },
  effects: dispatch => ({}),
});
