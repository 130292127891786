import { Models } from "@rematch/core";
import { patient } from "./patient";
import { auth } from "./auth";
import { editingDiagnosis } from "./editingDiagnosis";
import { pendingDiagnoses } from "./pendingDiagnoses";
import { error } from "./error";
import { patientAudits } from "./patientAudits";
import { auditProgress } from "./auditProgress";
import { dxTableState } from "./dxTableState";
import { users } from "./users";
import { addingUser } from "./addingUser";
import { editingUser } from "./editingUser";
import { userTableState } from "./userTableState";
import { patientAuditTableState } from "./patientAuditTableState";

export interface RootModel extends Models<RootModel> {
  patient: typeof patient;
  auth: typeof auth;
  editingDiagnosis: typeof editingDiagnosis;
  pendingDiagnoses: typeof pendingDiagnoses;
  error: typeof error;
  dxTableState: typeof dxTableState;
  patientAudits: typeof patientAudits;
  auditProgress: typeof auditProgress;
  users: typeof users;
  addingUser: typeof addingUser;
  editingUser: typeof editingUser;
  userTableState: typeof userTableState;
  patientAuditTableState: typeof patientAuditTableState;
}
export const models: RootModel = {
  auth,
  patient,
  editingDiagnosis,
  pendingDiagnoses,
  patientAudits,
  error,
  dxTableState,
  auditProgress,
  users,
  addingUser,
  editingUser,
  userTableState,
  patientAuditTableState,
};
