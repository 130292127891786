import * as React from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dispatch } from "@src/store";

export function SignOut() {
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    dispatch.auth.logoutAsync();
  }, []);
  return <Navigate to="/signin" replace />;
}

export default SignOut;
