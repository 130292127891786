import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button } from "@mui/material";
import { Confirmation } from "@molecules/Confirmation";
import { logger } from "@src/logger";
import { RootState, Dispatch } from "@src/store";
import DrawerTemplate from "@templates/DrawerTemplate";
import { DxTable } from "@organisms/DxTable";

export function RerunAuditPane() {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const patient = useSelector((state: RootState) => state.patient);

  async function handleReRunPatient() {
    if (!patient.audit) throw new Error("No patient audit is selected");

    try {
      setOpenConfirmation(false);
      await dispatch.patientAudits.rerunAuditAsync();
      navigate("/workbench");
    } catch (error) {
      dispatch.error.logError(error);
      logger.error(error);
    }
  }

  return (
    <>
      <Grid>
        <Button variant="contained" onClick={() => setOpenConfirmation(true)}>
          Re-run Patient
        </Button>
      </Grid>
      <Confirmation
        open={openConfirmation}
        title="Are you sure you want to re-run this patient analysis?"
        message="This is a long running process that could take up to 1 minute. Are you sure you want to continue?"
        onCancel={() => setOpenConfirmation(false)}
        onConfirm={handleReRunPatient}
      />
    </>
  );
}

export function PendingDiagnoses() {
  const { patientId } = useParams();
  const dispatch = useDispatch<Dispatch>();

  const pendingDiagnoses = useSelector(
    (state: RootState) => state.pendingDiagnoses,
  );

  const handleRefresh = function () {
    dispatch.pendingDiagnoses.fetchPendingDxAsync(patientId);
  };

  useEffect(() => {
    dispatch.pendingDiagnoses.fetchPendingDxAsync(patientId);
  }, [patientId]);

  return (
    <DrawerTemplate title="Workbench">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DxTable
            diagnoses={pendingDiagnoses}
            columnVisibility={{
              isInPcc: false,
              isInGm: false,
              isInGmPrior: false,
              isChronicUntreated: false,
              isInClaims: false,
              sourcePatientId: true,
            }}
          />
          {patientId && (
            <>
              <Box
                className="mt-2"
                sx={{ p: 2, display: "flex", flexDirection: "column" }}
              />
              <Button
                variant="contained"
                onClick={handleRefresh}
                sx={{ mb: 2 }}
              >
                Refresh
              </Button>
              <RerunAuditPane />
            </>
          )}
        </Grid>
      </Grid>
    </DrawerTemplate>
  );
}

export default PendingDiagnoses;
