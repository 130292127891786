import * as React from "react";
import { Alert, AlertColor } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "@src/store";

interface NotificationProps {
  type: string;
}

export function Notification({ type }: NotificationProps) {
  const error = useSelector((state: RootState) => state.error);
  const dispatch = useDispatch<Dispatch>();

  const message = error.error?.response
    ? `${error.error?.response.data?.detail}`
    : `${error.error?.message}`;

  return (
    <Alert
      sx={{ mb: 2 }}
      severity={type as AlertColor}
      onClose={() => {
        dispatch.error.clearError();
      }}
    >
      {message}
    </Alert>
  );
}
