import { createModel } from "@rematch/core";
import { logger } from "@src/logger";

import { RootModel } from "@src";
import { QueryableDiagnosis } from "@models/types";

export const pendingDiagnoses = createModel<RootModel>()({
  state: [] as QueryableDiagnosis[],
  reducers: {
    setDiagnoses(state, payload: QueryableDiagnosis[]): QueryableDiagnosis[] {
      state = payload;
      return state;
    },
    addDiagnosis(state, payload: QueryableDiagnosis): QueryableDiagnosis[] {
      state.push(payload);
      return state;
    },
    updateDiagnosis(state, payload: QueryableDiagnosis): QueryableDiagnosis[] {
      state = state.map(dx => {
        if (dx.id === payload.id) {
          return payload;
        }
        return dx;
      });
      return state;
    },
    deleteDiagnosis(state, payload: QueryableDiagnosis): QueryableDiagnosis[] {
      state = state.filter(dx => dx.id !== payload.id);
      return state;
    },
    clear(): QueryableDiagnosis[] {
      return [];
    },
  },
  effects: dispatch => ({
    async fetchPendingDxAsync(patientId?: string) {
      try {
        const api = dispatch.auth.getAuthorizedApi();
        const apiPath = patientId
          ? `/patient_audits/${patientId}/diagnoses`
          : "/diagnoses";
        const res = await api.get(apiPath);
        const dxs: QueryableDiagnosis[] = [];
        for (const dx of res.data) {
          dxs.push(dx as QueryableDiagnosis);
        }

        dispatch.pendingDiagnoses.setDiagnoses(dxs);
        return dxs;
      } catch (error) {
        dispatch.error.logError(error);
        logger.error(error);
        return [];
      }
    },
    async deleteQueryableDxAsync(payload: QueryableDiagnosis, rootState) {
      try {
        const patientId = payload.sourcePatientId;
        const api = dispatch.auth.getAuthorizedApi();
        await api.delete(`/diagnoses/${payload.id}`);
        dispatch.pendingDiagnoses.deleteDiagnosis(payload);
        // if current workbench patient is the same as the deleted patient dx, update the dx in the list
        if (
          String(patientId) === String(rootState.patient.info.sourcePatientId)
        ) {
          const updates = {
            ...payload,
          } as QueryableDiagnosis;
          updates.id = undefined;
          updates.status = undefined;
          dispatch.patient.updateSuggestedDx(updates);
        }
      } catch (error) {
        dispatch.error.logError(error);
        logger.error(error);
      }
    },
  }),
});
